import MuiButton, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

interface Props extends ButtonProps {
  loading?: Boolean;
  loadingColor?: 'inherit' | 'primary' | 'secondary';
  loadingType?: 'circular' | 'linear';
}

export const Button: React.FC<Props> = React.forwardRef((props: Props, ref) => {
  const {
    className: classNameProps,
    children,
    size,
    disabled,
    loading = false,
    loadingColor = 'primary',
    loadingType = 'linear',
    ...other
  } = props;

  const circularProgressSize = React.useMemo(() => {
    switch (size) {
      case 'small':
        return '1rem';
      case 'medium':
        return '1.5rem';
      case 'large':
        return '2rem';
      default:
        return '1rem';
    }
  }, [size]);

  const loadingIndicator = React.useMemo(() => {
    switch (loadingType) {
      case 'linear':
        return (
          <LinearProgress
            style={{ position: 'absolute', width: '100%', top: 0, left: 0 }}
          />
        );

      default:
        return (
          <CircularProgress
            size={circularProgressSize}
            style={{ position: 'absolute' }}
            color={loadingColor}
          />
        );
    }
  }, [circularProgressSize, loadingColor, loadingType]);

  return (
    <MuiButton
      className={classNameProps}
      ref={ref}
      size={size}
      disabled={!!(disabled || loading)}
      {...other}
    >
      {props.children}
      {loading && loadingIndicator}
    </MuiButton>
  );
});

export default Button;
